<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <div>
            <h1 class="intro-section-title">8. Mantelzorger</h1>
            <div class="row">
              <p class="intro-section-text" v-if="selectedLevel!=='dementienetwerk'">
              In deze analyses wordt het welzijn van potentiële mantelzorgers, de partners van personen met dementie, onderzocht vlak voor, tijdens en na het moment van overlijden van de persoon met dementie. We kijken hier specifiek naar de partner van de persoon met dementie omdat dit de voornaamste potentiele mantelzorger is.</p>
              <p class="intro-section-text" v-if="selectedLevel!=='dementienetwerk'">
                Samenwonend betekent dat er tot vlak voor overlijden nog werd samengewoond met de persoon met dementie. Een reden waarom mensen niet meer samenwonen is bijvoorbeeld dat de persoon met dementie in een verpleeghuis woonde en de partner niet. De weergegeven data is afkomstig uit het geselecteerde jaar.
              </p>
              <p class="intro-section-text" v-if="selectedLevel==='dementienetwerk'">
                Op deze pagina vindt u informatie van twee verschillende bronnen. Het eerste gedeelte is afkomstig van het Centraal Bureau voor de Statistiek (CBS). In deze analyses wordt het welzijn van potentiële mantelzorgers, de partners van personen met dementie, onderzocht vlak voor, tijdens en na het moment van overlijden van de persoon met dementie. We kijken hier specifiek naar de partner van de persoon met dementie omdat dit de voornaamste potentiele mantelzorger is. <span style="font-style: italic;">Deze informatie is per jaar beschikbaar en op niveau van dementienetwerk of zorgkantoor (mits mogelijk gezien het risico op onthulling van individuele zorginstellingen)</span>. U kijkt nu naar data van het jaar <span style="font-weight: bold;">{{selectedYear}}</span>.
              </p>
              <p class="intro-section-text" v-if="selectedLevel==='dementienetwerk'">
              Het tweede gedeelte van de data is afkomstig van de <a class="custom-link" href="https://www.alzheimer-nederland.nl/belangenbehartiging/dementiemonitor">Dementiemonitor Mantelzorg 2022 </a> uitgevoerd door <a class="custom-link" href="https://www.alzheimer-nederland.nl/"> Alzheimer Nederland</a>. <span style="font-style: italic;">Deze monitor is op deze pagina alleen beschikbaar voor 2022 en alleen op niveau van dementienetwerk.</span>. 
              </p>
            </div>

            <div class="row" v-if="selectedLevel==='dementienetwerk'">
              <div class="d-flex align-items-center justify-content-evenly">
                  <button @click="scrollToFirstDataSection" class="custom-button">CBS-data</button>
                  <button @click="scrollToSecondDataSection" class="custom-button">Dementiemonitor 2022</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="firstDataSection" ref="firstDataSection" v-if="selectedLevel==='dementienetwerk'" class="row first-section-space">
        <div class="col-sm-12 down">
          <div class="first-section-colour-block">
            <h1 class="first-section-title">CBS-DATA</h1>
            <div class="row">
              <p class="first-section-text">
                  In deze analyses wordt het welzijn van potentiële mantelzorgers, de partners van personen met dementie, onderzocht vlak voor, tijdens en na het moment van overlijden van de persoon met dementie. We kijken hier specifiek naar de partner van de persoon met dementie omdat dit de voornaamste potentiële mantelzorger is. 
                  Samenwonend betekent dat er tot vlak voor overlijden nog werd samengewoond met de persoon met dementie. Een reden waarom mensen niet meer samenwonen is bijvoorbeeld dat de persoon met dementie in een verpleeghuis woonde en de partner niet. 
              </p>
            </div>
          </div>
        </div>
      </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="row" v-if="selectedLevel==='zorgkantoor'">
          <div class="col-sm-12 down">
            <card ref="firstCard" :cardId="'factsheet8-1'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:-  Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie oselectedLevel===verleed.'" 
              :x="zorgkantoorregionaam_table" :y="antidepressiva_t" 
              :z="antidepressiva_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="3550">
              <template slot="card-header">Percentage partners met antidepressiva in jaren voor en na overlijden persoon met dementie</template>
              <div slot="card-body">
                Het percentage van samenwonende partners dat antidepressiva gebruikt, van twee jaar vóór overlijden tot twee jaar na het overlijdens van de persoon met dementie waarvoor gezorgd wordt. In het jaar van overlijden heeft iedereen de diagnose dementie.
                <line-chart-for v-if="load.graphOne" :max="30"
                :tickvals="[-2,-1,0,1,2]"
                :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
                :xtitle="'Tijdstip'"
                :ytitle="'% met antidepressiva'"
                :xdata="t_dementie_landelijk"
                :ydata="antidepressiva_landelijk"
                :zorgkantoor="zorgkantoor"/>
              </div>
            </card>
            <card-small :cardId="'factsheet8-1-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
              <template slot="card-header">Percentage mantelzorgers met antidepressiva in jaren voor en na overlijden zorgbehoevende</template>
            </card-small>
          </div>
        </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'factsheet8-2'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:-  Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'" 
            :x="zorgkantoorregionaam_table" :y="antidepressiva_t" 
              :z="verpleeghuis_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="3550">
            <template slot="card-header">Percentage in verpleeghuis in jaren voor en na overlijden persoon met dementie</template>
            <div slot="card-body">
              Het percentage samenwonende partners dat in een verpleeghuis woont, van twee jaar vóór overlijden tot twee jaar na overlijden van de persoon met dementie waar voor gezorgd wordt.
              <line-chart-for v-if="load.graphOne" :max="20"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :xtitle="'Tijdstip'"
              :ytitle="'% in verpleeghuis'"
              :xdata="t_dementie_landelijk"
              :ydata="verpleeghuis_landelijk"
              :zorgkantoor="zorgkantoor"/>
            </div>
          </card>
          <card-small :cardId="'factsheet8-2-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 down">
          <card :cardId="'factsheet8-3'" v-if="selectedLevel==='zorgkantoor'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:-  Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'"
            :x="zorgkantoorregionaam_table" :y="antidepressiva_t" 
              :z="wmo_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="3550">
            <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden</template>
            <div slot="card-body">
              Het percentage samenwonende partners dat enige ondersteuning vanuit de wmo ontvangt, van twee jaar vóór overlijden tot twee jaar na overlijden van de persoon met dementie waar voor gezorgd wordt.
              <line-chart-for v-if="load.graphOne"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :xtitle="'Tijdstip'"
              :ytitle="'% met hulpmiddelen of diensten'"
              :xdata="t_dementie_landelijk"
              :ydata="wmo_landelijk"
              :zorgkantoor="zorgkantoor"/>
            </div>
          </card>
          <card-small :cardId="'factsheet8-3-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>


      <div class="col-sm-12 down">
        <card :cardId="'factsheet8-4'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:- Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'" 
              :x="t_antidepressiva" :y="groep_antidepressiva" :z="antidepressiva_perc.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 400 : 400">
          <template slot="card-header">Antidepressivagebruik door partners - nationale cijfers</template>
          <div slot="card-body">
            Antidepressivagebruik door partners in de 2 jaar voor en na overlijden van de persoon met dementie. Er wordt onderscheid gemaakt tussen partners die samenwonen met de persoon met dementie op het tijdstip van overlijden van de persoon met dementie en partners die niet samen wonen.
              <grouped-bar-chart-numbers
              :names="['Samenwonend','Niet samenwonend']"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :xtitle="'Tijdstip'"
              :ytitle="'% met antidepressiva'" :ticksuffix="'%'" v-if="load.graphTwo"
              :x="[t_samen,t_alleen]"
              :y="[antidepressiva_samen,antidepressiva_alleen]"
              />
          </div>
        </card>
      </div>

      <div class="col-sm-12 down">
        <card :cardId="'factsheet8-5'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:- Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'" 
              :x="t_antidepressiva" :y="groep_antidepressiva" :z="psycho_perc.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 400 : 400">
          <template slot="card-header">Psychofarmaca-gebruik door partners - nationale cijfers</template>
          <div slot="card-body">
            Psychofarmaca-gebruik door partners in de 2 jaar voor en na overlijden van de persoon met dementie. Er wordt onderscheid gemaakt tussen partners die samenwonen met de persoon met dementie op het tijdstip van overlijden van de persoon met dementie en partners die niet samen wonen.
              <grouped-bar-chart-numbers
              :names="['Samenwonend','Niet samenwonend']"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :xtitle="'Tijdstip'"
              :ytitle="'% met psychofarmaca'" :ticksuffix="'%'" v-if="load.graphTwo"
              :x="[t_samen,t_alleen]"
              :y="[psycho_samen,psycho_alleen]"
              />
          </div>
        </card>
      </div>

      <div class="col-sm-12 down">
        <card :cardId="'factsheet8-6'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:- Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'" 
              :x="t_antidepressiva" :y="groep_antidepressiva" :z="vh_perc.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 400 : 400">
          <template slot="card-header">Partners wonend in verpleeghuis - nationale cijfers</template>
          <div slot="card-body">
            Percentage partners dat woont in een verpleeghuis in de 2 jaar voor en na overlijden van de persoon met dementie. Er wordt onderscheid gemaakt tussen partners die samenwonen met de persoon met dementie op het tijdstip van overlijden van de persoon met dementie en partners die niet samen wonen.
              <grouped-bar-chart-numbers
              :names="['Samenwonend','Niet samenwonend']"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :x="[t_samen,t_alleen]"
              :y="[vh_samen,vh_alleen]"
              :xtitle="'Tijdstip'"
              :ytitle="'% in verpleeghuis'" :ticksuffix="'%'" v-if="load.graphTwo"
              />
          </div>
        </card>
      </div>

      <div class="col-sm-12 down">
        <card :cardId="'factsheet8-7'" :hovertext="'Een partner wordt gedefinieerd als iemand die voldoet aan drie criteria:- Minimaal 5 jaar heeft samengewoond met een persoon met dementie die overleed in de periode 2015-2019.  -  De periode waarin ze samenwoonden eindigde maximaal 5 jaar voor het overlijden van de persoon met dementie.  -  De persoon was nog in leven toen de persoon met dementie overleed.'" 
          :x="t_antidepressiva" :y="groep_antidepressiva" :z="wmo_zonder_perc.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 400 : 400">
          <template slot="card-header">Partners met Wmo-gefinancierde zorg en ondersteuning (exclusief hulpmiddelen) - nationale cijfers</template>
          <div slot="card-body">
            Percentage partners met wmo gebruik zonder hulpmiddelen in de 2 jaar voor en na overlijden van de persoon met dementie. Er wordt onderscheid gemaakt tussen partners die samenwonen met de persoon met dementie op het tijdstip van overlijden van de persoon met dementie en partners die niet samen wonen.
              <grouped-bar-chart-numbers
              :names="['Samenwonend','Niet samenwonend']"
              :tickvals="[-2,-1,0,1,2]"
              :ticktext="['2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden persoon met dementie', '1 jaar na overlijden', '2 jaar na overlijden']"
              :x="[t_samen,t_alleen]"
              :y="[wmo_zonder_samen,wmo_zonder_alleen]"
              :xtitle="'Tijdstip'"
              :ytitle="'% met wmo zonder hulpmiddelen'" :ticksuffix="'%'" v-if="load.graphTwo"
              />
          </div>
        </card>
      </div>

      <div id="secondDataSection" ref="secondDataSection" v-if="selectedLevel==='dementienetwerk'" class="row section-space">
        <div class="col-sm-12 down">
          <div class="second-section-colour-block">
            <h1 class="second-section-title">Dementiemonitor 2022</h1>
            <p class="second-section-text">
              Alzheimer Nederland heeft in samenwerking met Nivel een lijst met vragen gesteld aan <span style="font-weight: bold;">4531</span> mantelzorgers in Nederland om erachter te komen wat leeft onder mantelzorgers en hoe zij de zorg aan de naaste met dementie ervaren. Er is een selectie gemaakt van vragen om hier weer te geven. Als u meer wilt weten kunt u <a class="custom-link" href="https://www.alzheimer-nederland.nl/belangenbehartiging/dementiemonitor"> hier</a> meer informatie vinden. Let op: voor de interpretatie van deze resultaten plaatsen wij de volgende kanttekeningen:
            </p>

            <p class="second-section-text">
              1) de uitvraag aan mantelzorgers kan per regio vanuit een ander kanaal hebben plaatsgevonden waardoor een andere groep mantelzorgers is bereikt. Daarnaast is het aantal respondenten voor kleinere regio’s beperkt waardoor de resultaten mogelijk instabieler zijn.
            </p>
          </div>
        </div>
      </div>


      <div class="row" v-if="selectedLevel==='dementienetwerk'">
            <div class="col-sm-12 down">
              <card :cardId="'dementienetwerk_mantelzorgrelatie-1'" :hovertext="'Deze informatie is afkomstig van de Dementiemonitor mantelzorg 2022 van Alzheimer Nederland.'" 
                :x="dementienetwerkregionaam_mantelzorger" :y="groep_mantelzorger" :z="perc_mantelzorger.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
                :tableHeight="5000">
                <template slot="card-header">Relatie van de mantelzorger met de patiënt</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphFour" 
                    :ytitle="'% in relatie van de mantelzorger '"
                    :names="['Partner', '(Schoon)dochter/zoon', 'Andere familieleden', 'Vriend(in), kennis, buren', 'Anders']"
                    :x="[dementienetwerkregionaam_partner, dementienetwerkregionaam_schoon, dementienetwerkregionaam_familie, dementienetwerkregionaam_kennis, dementienetwerkregionaam_anders]"
                    :y="[antwoord_partner, antwoord_schoon, antwoord_familie, antwoord_kennis, antwoord_anders]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel==='dementienetwerk'">
            <div class="col-sm-12 down">
              <card :cardId="'dementienetwerk_mantelzorgzorgt-2'" :hovertext="'Deze informatie is afkomstig van de Dementiemonitor mantelzorg 2022 van Alzheimer Nederland.'" 
                :x="dementienetwerkregionaam_frequentie" :y="groep_frequentie" 
                :z="perc_frequentie.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Groep'" :ztitle="'Percentage'" 
                :tableHeight="7000">
                <template slot="card-header">Frequentie van zorg voor de naaste door de mantelzorger</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphFive" 
                    :ytitle="'% in frequentie van zorg'"
                    :names="['Dag en nacht', 'Dagelijks', '3-6 keer per week', '1-2 keer per week', '<1 keer per week', '< 1 keer per maand', 'Wisselend']"
                    :x="[dementienetwerkregionaam_dag_nacht, dementienetwerkregionaam_dagelijks, dementienetwerkregionaam_3_6_week, dementienetwerkregionaam_1_2_week, dementienetwerkregionaam_less_1_week, dementienetwerkregionaam_less_1_maand, dementienetwerkregionaam_wisselend]"
                    :y="[antwoord_dag_nacht, antwoord_dagelijks, antwoord_3_6_week, antwoord_1_2_week, antwoord_less_1_week, antwoord_less_1_maand, antwoord_wisselend]"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel==='dementienetwerk'">
            <div class="col-sm-12 down">
              <card :cardId="'dementienetwerk_mantelzorgbelasting-3'" :hovertext="'Deze informatie is afkomstig van de Dementiemonitor mantelzorg 2022 van Alzheimer Nederland.'" 
                :x="dementienetwerkregionaam_belasting" :y="groep_belasting" 
                :z="perc_belasting.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Groep'" :ztitle="'Percentage'" 
                :tableHeight="6000">
                <template slot="card-header">De belasting die de mantelzorg ervaart bij de zorg</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphSix" 
                    :ytitle="'% in de belasting die de mantelzorg ervaart'"
                    :names="['Niet of nauwelijks', 'Enigszins', 'Tamelijk zwaar', 'Zeer zwaar', 'Overbelast']"
                    :x="[dementienetwerkregionaam_nauwelijks, dementienetwerkregionaam_enigszins, dementienetwerkregionaam_tamelijkzwaar, dementienetwerkregionaam_zeerzwaar, dementienetwerkregionaam_overbelast]"
                    :y="[antwoord_nauwelijks, antwoord_enigszins, antwoord_tamelijkzwaar, antwoord_zeerzwaar, antwoord_overbelast]"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel==='dementienetwerk'">
            <div class="col-sm-12 down">
              <card :cardId="'dementienetwerk_mantelzorgaantaluren-1'" :hovertext="'Deze informatie is afkomstig van de Dementiemonitor mantelzorg 2022 van Alzheimer Nederland.'" 
                :x="dementienetwerkregionaam_aantaluren" 
                :y="antwoord_aantaluren.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="1500">
                <template slot="card-header">Gemiddeld aantal uren zorg per week</template>
                <div slot="card-body">
                    <grouped-bar-chart v-if="load.graphSeven" 
                    :ytitle="'Aantal uur per week'"
                    :x="[dementienetwerkregionaam_aantaluren]"
                    :y="[antwoord_aantaluren]"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel==='dementienetwerk'">
            <div class="col-sm-12 down">
              <card :cardId="'dementienetwerk_mantelzorglevenrapportcijfer-1'" :hovertext="'Deze informatie is afkomstig van de Dementiemonitor mantelzorg 2022 van Alzheimer Nederland.'" 
                :x="dementienetwerkregionaam_levenrapportcijfer" 
                :y="antwoord_levenrapportcijfer.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="1500">
                <template slot="card-header">Rapportcijfer dat de mantelzorgers aan hun eigen leven geeft</template>
                <div slot="card-body">
                    <grouped-bar-chart v-if="load.graphEight" 
                    :ytitle="'Rapportcijfer'"
                    :x="[dementienetwerkregionaam_levenrapportcijfer]"
                    :y="[antwoord_levenrapportcijfer]"
                    />
                </div>
              </card>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-6 down">
              <card :cardId="'landelijk-relatie-1'" :hovertext="''" 
                :x="dementienetwerkregionaam_mantelzorger" 
                :y="perc_mantelzorger.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="400">
                <template slot="card-header">Relatie van de mantelzorger met de patiënt</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphTen" :isLandelijk='false'
                    :ytitle="'% in relatie van de mantelzorger '"
                    :names="['Partner', '(Schoon)dochter/zoon', 'Andere familieleden', 'Vriend(in), kennis, buren', 'Anders']"
                    :x="[dementienetwerkregionaam_partner, dementienetwerkregionaam_schoon, dementienetwerkregionaam_familie, dementienetwerkregionaam_kennis, dementienetwerkregionaam_anders]"
                    :y="[antwoord_partner, antwoord_schoon, antwoord_familie, antwoord_kennis, antwoord_anders]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>

            <div class="col-sm-6 down">
              <card :cardId="'landelijk-frequentie-1'" :hovertext="''" 
                :x="dementienetwerkregionaam_frequentie" 
                :y="perc_frequentie.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="400">
                <template slot="card-header">Frequentie van zorg voor de naaste door de mantelzorger</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphTen" :isLandelijk='false'
                    :ytitle="'% in frequentie van zorg'"
                    :names="['Dag en nacht', 'Dagelijks', '3-6 keer per week', '1-2 keer per week', '<1 keer per week', '< 1 keer per maand', 'Wisselend']"
                    :x="[dementienetwerkregionaam_dag_nacht, dementienetwerkregionaam_dagelijks, dementienetwerkregionaam_3_6_week, dementienetwerkregionaam_1_2_week, dementienetwerkregionaam_less_1_week, dementienetwerkregionaam_less_1_maand, dementienetwerkregionaam_wisselend]"
                    :y="[antwoord_dag_nacht, antwoord_dagelijks, antwoord_3_6_week, antwoord_1_2_week, antwoord_less_1_week, antwoord_less_1_maand, antwoord_wisselend]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>
          </div>
      </div>
    </div>

    <div class="row">
          <div class="col-sm-12">
        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-6 down">
              <card :cardId="'landelijk-belasting-1'" :hovertext="''" 
                :x="dementienetwerkregionaam_belasting" :y="groep_belasting"
                :z="perc_belasting.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Groep'" :ztitle="'Percentage'" 
                :tableHeight="500">
                <template slot="card-header">De belasting die de mantelzorg ervaart bij de zorg</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphTen" :isLandelijk='false'
                    :ytitle="'% in de belasting die de mantelzorg ervaart'"
                    :names="['Niet of nauwelijks', 'Enigszins', 'Tamelijk zwaar', 'Zeer zwaar', 'Overbelast', 'SOM: Tamelijk, (zeer) zwaar of overbelast']"
                    :x="[dementienetwerkregionaam_nauwelijks, dementienetwerkregionaam_enigszins, dementienetwerkregionaam_tamelijkzwaar, dementienetwerkregionaam_zeerzwaar, dementienetwerkregionaam_overbelast, dementienetwerkregionaam_som]"
                    :y="[antwoord_nauwelijks, antwoord_enigszins, antwoord_tamelijkzwaar, antwoord_zeerzwaar, antwoord_overbelast, antwoord_som]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>

            <div class="col-sm-6 down">
              <card :cardId="'landelijk-aantal-1'" :hovertext="''" 
                :x="dementienetwerkregionaam_aantaluren" 
                :y="antwoord_aantaluren_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Gemiddeld aantal uren zorg per week</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphTen" :isLandelijk='false'
                    :ytitle="'% aantal uren zorg '"
                    :x="[dementienetwerkregionaam_aantaluren]"
                    :y="[antwoord_aantaluren]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>
          </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-6 down">
              <card :cardId="'landelijk-rapportcijfer-1'" :hovertext="''" 
                :x="dementienetwerkregionaam_levenrapportcijfer" 
                :y="antwoord_levenrapportcijfer_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Rapportcijfer dat de mantelzorgers aan hun eigen leven geeft</template>
                <div slot="card-body">
                    <stacked-bar-chart-colors v-if="load.graphTen" :isLandelijk='false'
                    :ytitle="'% in rapportcijfer'"
                    :x="[dementienetwerkregionaam_levenrapportcijfer]"
                    :y="[antwoord_levenrapportcijfer]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>
        </div>
      </div>
    </div>
    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>
<script>

// import SideBarRight from '../components/SideBarRight'
import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import StackedBarChartColors from '../components/StackedBarChartColors'
import LineChartFor from '../components/LineChartFor'
import GroupedBarChart from '../components/GroupedBarChart'
import GroupedBarChartNumbers from '../components/GroupedBarChartNumbers'

import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Mantelzorg',
  },
  components: { Card, CardSmall,  LineChartFor, GroupedBarChart, GroupedBarChartNumbers, 
    StackedBarChartColors, Footer},
  data () {
    return {
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
        graphSix: false,
        graphSeven: false,
        graphEight: false,
        graphNine: false,
        graphTen: false
      },
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      footerZorgkantoor: 'footer-zorgkantoor',
      footerDementienetwerk: 'footer-dementienetwerk',
      t_antidepressiva: [],
      antidepressiva_perc:[],
      dementienetwerkregionaam_partner: [],
      antwoord_partner: [],
      dementienetwerkregionaam_schoon: [],
      antwoord_schoon: [],
      dementienetwerkregionaam_familie: [],
      antwoord_familie: [],
      dementienetwerkregionaam_kennis: [],
      antwoord_kennis: [],
      dementienetwerkregionaam_anders: [],
      antwoord_anders: [],

      dementienetwerkregionaam_relatie :[],
      perc_relatie: [],

      dementienetwerkregionaam_mantelzorger :[],
      groep_mantelzorger : [],
      perc_mantelzorger : [],

      dementienetwerkregionaam_frequentie :[],
      groep_frequentie :[],
      perc_frequentie :[],

      dementienetwerkregionaam_dag_nacht: [],
      antwoord_dag_nacht :[],
      dementienetwerkregionaam_dagelijks: [],
      antwoord_dagelijks: [],
      dementienetwerkregionaam_3_6_week: [],
      antwoord_3_6_week: [],
      dementienetwerkregionaam_1_2_week: [],
      antwoord_1_2_week: [],
      dementienetwerkregionaam_less_1_week: [],
      antwoord_less_1_week:[],
      dementienetwerkregionaam_less_1_maand: [],
      antwoord_less_1_maand:[],
      dementienetwerkregionaam_wisselend: [],
      antwoord_wisselend: [],

      dementienetwerkregionaam_belasting:[],
      groep_belasting :[],
      perc_belasting:[],

      dementienetwerkregionaam_nauwelijks: [],
      antwoord_nauwelijks :[],
      dementienetwerkregionaam_enigszins: [],
      antwoord_enigszins: [],
      dementienetwerkregionaam_tamelijkzwaar: [],
      antwoord_tamelijkzwaar: [],
      dementienetwerkregionaam_zeerzwaar: [],
      antwoord_zeerzwaar: [],
      dementienetwerkregionaam_overbelast: [],
      antwoord_overbelast:[],
      dementienetwerkregionaam_som: [],
      antwoord_som:[],

      dementienetwerkregionaam_aantaluren: [],
      antwoord_aantaluren_table :[],
      antwoord_levenrapportcijfer_table:[],
      antwoord_aantaluren: [],
      dementienetwerkregionaam_levenrapportcijfer:[],
      antwoord_levenrapportcijfer: [],
      dementienetwerkregionaam_socialeleven:[],
      antwoord_socialeleven: [],

      t_dementie_landelijk: [],
      wmo_landelijk: [],
      verpleeghuis_landelijk: [],
      hulpmiddelendiensten_landelijk: [],
      zorgkantoorregionamen: [],
      zorgkantoor: [],
      t: [],
      antidepressiva_samen: [],
      antidepressiva_alleen: [],
      t_samen: [],
      t_alleen: [],
      percentage_opname_ziekenhuis: [],
      zorgkantoorregionaam: [],
      gemDagenEersteOpname: [],
      percentage_enigGebruikVerpleeghuis: [],
      percentage_percentage_enigGebruikWmo: [],
      antidepressiva_landelijk: [],
      psycho_samen: [],
      psycho_alleen: [],
      vh_alleen: [],
      vh_samen: [],
      vh_perc: [],
      wmo_zonder_samen: [],
      wmo_zonder_perc: [],
      wmo_zonder_alleen: [],
      antidepressiva_table: [],
      antidepressiva_t :[],
      zorgkantoorregionaam_table: [],
      verpleeghuis_table: [],
      wmo_table: [],
      groep_antidepressiva: [],
      psycho_perc:[],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadNationalData ()
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear']),

      footerClass(){
        if (this.$store.state.selectedLevel === 'zorgkantoor') {
          return this.footerZorgkantoor;
        }
        else{
          return this.footerDementienetwerk;
        }
      }
  },
  methods: {
    scrollToFirstDataSection() {
      if (this.$refs.firstDataSection) {
        this.$refs.firstDataSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToSecondDataSection() {
      if (this.$refs.secondDataSection) {
        this.$refs.secondDataSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    loadAllData () {
        if (this.selectedLevel=='zorgkantoor'){
          this.$http.get(`/api/zorgkantoor_mantelzorg/get_data/?jaar=${this.$store.state.selectedYear}`).then((response) => {
            response.data.sort((a,b) => a.zorgkantoorregiocode-b.zorgkantoorregiocode).filter(x => x.samenwonend_overlijden===0)
            response.data = response.data.filter(x => x.samenwonend_overlijden===1)
            this.zorgkantoorregionamen = [...new Set(response.data.map(d => d.zorgkantoorregionaam))]
            for (var i = 0; i<this.zorgkantoorregionamen.length; i++) {
              this.wmo_landelijk[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.percentage_wmo))
              this.verpleeghuis_landelijk[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.percentage_aantal_verpleeghuis))
              this.hulpmiddelendiensten_landelijk[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.percentage_hulpmiddelend_diensten))
              this.antidepressiva_landelijk[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.percentage_antidepressiva))
              this.t_dementie_landelijk[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.t))
              this.zorgkantoor[i] = response.data.filter(x => x.zorgkantoorregionaam==this.zorgkantoorregionamen[i]).map(d => (d.zorgkantoorregionaam))[0]
              }

            const tMapping = {
              '-2': '2 jaar voor overlijden',
              '-1': '1 jaar voor overlijden',
              '0': 'jaar van overlijden persoon met dementie',
              '1': '1 jaar na overlijden',
              '2': '2 jaar na overlijden'
            };

            // mapping the t codes by using the defined mapping
            this.antidepressiva_t = response.data.map(d => tMapping[d.t]);
            this.zorgkantoorregionaam_table = response.data.map(d => d.zorgkantoorregionaam)
            this.antidepressiva_table = response.data.map(d => (d.percentage_antidepressiva).toFixed(1))
            this.verpleeghuis_table = response.data.map(d => (d.percentage_aantal_verpleeghuis).toFixed(1))
            this.wmo_table = response.data.map(d => (d.percentage_wmo).toFixed(1))
            this.load.graphOne=true
          }),
          this.$http.get(`/api/zorgkantoor_us_zorggebruik/get_data/?jaar=${this.$store.state.selectedYear}`).then((response) => {
              response.data.sort((a,b) => b.percentage_enigGebruikWmo-a.percentage_enigGebruikWmo)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
              this.percentage_enigGebruikWmo = response.data.map(d => (d.percentage_enigGebruikWmo))
              this.percentage_enigGebruikVerpleeghuis = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis))
              this.gemDagenEersteOpname = response.data.map(d => (d.gemDagenEersteOpname).toFixed(0))
              this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
              this.load.graphThree=true
            })
      }
      else if(this.selectedLevel=='landelijk'){
        this.$http.get(`/api/dementienetwerk_mantelzorgrelatie/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_partner = response.data.filter(x => x.antwoord =='Partner').map(d => d.dementienetwerknaam)
              this.antwoord_partner = response.data.filter(x => x.antwoord =='Partner').map(d => (d.percentage))
  
              this.dementienetwerkregionaam_schoon = response.data.filter(x => x.antwoord =='(Schoon)dochter/(schoon)zoon').map(d => d.dementienetwerknaam)
              this.antwoord_schoon = response.data.filter(x => x.antwoord =='(Schoon)dochter/(schoon)zoon').map(d => (d.percentage))
  
              this.dementienetwerkregionaam_familie = response.data.filter(x => x.antwoord =='Andere familieleden').map(d => d.dementienetwerknaam)
              this.antwoord_familie = response.data.filter(x => x.antwoord =='Andere familieleden').map(d => (d.percentage))
  
              this.dementienetwerkregionaam_kennis = response.data.filter(x => x.antwoord =='Vriend(in), kennis, buren').map(d => d.dementienetwerknaam)
              this.antwoord_kennis = response.data.filter(x => x.antwoord =='Vriend(in), kennis, buren').map(d => (d.percentage))
  
              this.dementienetwerkregionaam_anders = response.data.filter(x => x.antwoord =='Anders (bijvoorbeeld ex-partners, ouders van vrienden)').map(d => d.dementienetwerknaam)
              this.antwoord_anders = response.data.filter(x => x.antwoord =='Anders (bijvoorbeeld ex-partners, ouders van vrienden)').map(d => (d.percentage))

              this.dementienetwerkregionaam_mantelzorger = response.data.map(d => d.dementienetwerknaam)
              this.groep_mantelzorger = response.data.map(x => x.antwoord)
              this.perc_mantelzorger = response.data.map(d => (d.percentage).toFixed(1))
              this.load.graphTen=true
            }),
        this.$http.get(`/api/dementienetwerk_mantelzorgzorgt/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
              (response) => {
                response.data.sort((a,b) => b.percentage-a.percentage)
                response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
                this.dementienetwerkregionaam_dag_nacht = response.data.filter(x => x.antwoord =='Dag en nacht').map(d => d.dementienetwerknaam)
                this.antwoord_dag_nacht = response.data.filter(x => x.antwoord =='Dag en nacht').map(d => (d.percentage))
                this.dementienetwerkregionaam_dagelijks = response.data.filter(x => x.antwoord =='Dagelijks').map(d => d.dementienetwerknaam)
                this.antwoord_dagelijks = response.data.filter(x => x.antwoord =='Dagelijks').map(d => (d.percentage))
                this.dementienetwerkregionaam_3_6_week = response.data.filter(x => x.antwoord =='3-6 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_3_6_week = response.data.filter(x => x.antwoord =='3-6 keer per week').map(d => (d.percentage))
                this.dementienetwerkregionaam_1_2_week = response.data.filter(x => x.antwoord =='1-2 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_1_2_week = response.data.filter(x => x.antwoord =='1-2 keer per week').map(d => (d.percentage))
                this.dementienetwerkregionaam_less_1_week = response.data.filter(x => x.antwoord =='< 1 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_less_1_week = response.data.filter(x => x.antwoord =='< 1 keer per week').map(d => (d.percentage))
                this.dementienetwerkregionaam_less_1_maand = response.data.filter(x => x.antwoord =='< 1 keer per maand').map(d => d.dementienetwerknaam)
                this.antwoord_less_1_maand = response.data.filter(x => x.antwoord =='< 1 keer per maand').map(d => (d.percentage))
                this.dementienetwerkregionaam_wisselend = response.data.filter(x => x.antwoord =='Wisselend').map(d => d.dementienetwerknaam)
                this.antwoord_wisselend = response.data.filter(x => x.antwoord =='Wisselend').map(d => (d.percentage))


                this.dementienetwerkregionaam_frequentie = response.data.map(d => d.dementienetwerknaam)
                this.groep_frequentie = response.data.map(x => x.antwoord)
                this.perc_frequentie =response.data.map(d => (d.percentage).toFixed(1))
                this.load.graphTen=true
              }),
        this.$http.get(`/api/dementienetwerk_mantelzorgervarenbelasting/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_nauwelijks = response.data.filter(x => x.antwoord =='Niet of nauwelijks').map(d => d.dementienetwerknaam)
              this.antwoord_nauwelijks = response.data.filter(x => x.antwoord =='Niet of nauwelijks').map(d => (d.percentage))

              this.dementienetwerkregionaam_enigszins = response.data.filter(x => x.antwoord =='Enigszins').map(d => d.dementienetwerknaam)
              this.antwoord_enigszins = response.data.filter(x => x.antwoord =='Enigszins').map(d => (d.percentage))

              this.dementienetwerkregionaam_tamelijkzwaar = response.data.filter(x => x.antwoord =='Tamelijk zwaar').map(d => d.dementienetwerknaam)
              this.antwoord_tamelijkzwaar = response.data.filter(x => x.antwoord =='Tamelijk zwaar').map(d => (d.percentage))
  
              this.dementienetwerkregionaam_zeerzwaar = response.data.filter(x => x.antwoord =='Zeer zwaar').map(d => d.dementienetwerknaam)
              this.antwoord_zeerzwaar = response.data.filter(x => x.antwoord =='Zeer zwaar').map(d => (d.percentage))

              this.dementienetwerkregionaam_overbelast = response.data.filter(x => x.antwoord =='Overbelast').map(d => d.dementienetwerknaam)
              this.antwoord_overbelast = response.data.filter(x => x.antwoord =='Overbelast').map(d => (d.percentage))

              this.dementienetwerkregionaam_som = response.data.filter(x => x.antwoord =='SOM: Tamelijk, (zeer) zwaar of overbelast').map(d => d.dementienetwerknaam)
              this.antwoord_som = response.data.filter(x => x.antwoord =='SOM: Tamelijk, (zeer) zwaar of overbelast').map(d => (d.percentage))

              this.dementienetwerkregionaam_belasting = response.data.map(d => d.dementienetwerknaam)
              this.groep_belasting = response.data.map(x => x.antwoord)
              this.perc_belasting = response.data.map(d => (d.percentage).toFixed(1))
              this.load.graphTen=true
            }),
          this.$http.get(`/api/dementienetwerk_aantaluren/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_aantaluren = response.data.filter(x => x.antwoord =='Gemiddelde aantal uren').map(d => d.dementienetwerknaam)
              this.antwoord_aantaluren = response.data.filter(x => x.antwoord =='Gemiddelde aantal uren').map(d => (d.percentage))
              this.antwoord_aantaluren_table = response.data.filter(x => x.antwoord =='Gemiddelde aantal uren').map(d => (d.percentage).toFixed(1))
              this.load.graphTen=true
            }),
          this.$http.get(`/api/dementienetwerk_levenrapportcijfer/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_levenrapportcijfer = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => d.dementienetwerknaam)
              this.antwoord_levenrapportcijfer = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => (d.percentage))
              this.antwoord_levenrapportcijfer_table = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => (d.percentage).toFixed(1))
              this.load.graphTen=true
            })
      }
        else{
          this.$http.get(`/api/dementienetwerk_mantelzorgrelatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_partner = response.data.filter(x => x.antwoord =='Partner').map(d => d.dementienetwerknaam)
              this.antwoord_partner = response.data.filter(x => x.antwoord =='Partner').map(d => (d.normalized_percentage))
  
              this.dementienetwerkregionaam_schoon = response.data.filter(x => x.antwoord =='(Schoon)dochter/(schoon)zoon').map(d => d.dementienetwerknaam)
              this.antwoord_schoon = response.data.filter(x => x.antwoord =='(Schoon)dochter/(schoon)zoon').map(d => (d.normalized_percentage))
  
              this.dementienetwerkregionaam_familie = response.data.filter(x => x.antwoord =='Andere familieleden').map(d => d.dementienetwerknaam)
              this.antwoord_familie = response.data.filter(x => x.antwoord =='Andere familieleden').map(d => (d.normalized_percentage))
  
              this.dementienetwerkregionaam_kennis = response.data.filter(x => x.antwoord =='Vriend(in), kennis, buren').map(d => d.dementienetwerknaam)
              this.antwoord_kennis = response.data.filter(x => x.antwoord =='Vriend(in), kennis, buren').map(d => (d.normalized_percentage))
  
              this.dementienetwerkregionaam_anders = response.data.filter(x => x.antwoord =='Anders (bijvoorbeeld ex-partners, ouders van vrienden)').map(d => d.dementienetwerknaam)
              this.antwoord_anders = response.data.filter(x => x.antwoord =='Anders (bijvoorbeeld ex-partners, ouders van vrienden)').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_mantelzorger = response.data.map(d => d.dementienetwerknaam)
              this.groep_mantelzorger = response.data.map(x => x.antwoord)
              this.perc_mantelzorger = response.data.map(d => (d.percentage).toFixed(1))
              this.load.graphFour=true
            }),
          this.$http.get(`/api/dementienetwerk_mantelzorgzorgt/get_data/?jaar=${this.$store.state.selectedYear}`).then(
              (response) => {
                response.data.sort((a,b) => b.percentage-a.percentage)
                response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
                this.dementienetwerkregionaam_dag_nacht = response.data.filter(x => x.antwoord =='Dag en nacht').map(d => d.dementienetwerknaam)
                this.antwoord_dag_nacht = response.data.filter(x => x.antwoord =='Dag en nacht').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_dagelijks = response.data.filter(x => x.antwoord =='Dagelijks').map(d => d.dementienetwerknaam)
                this.antwoord_dagelijks = response.data.filter(x => x.antwoord =='Dagelijks').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_3_6_week = response.data.filter(x => x.antwoord =='3-6 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_3_6_week = response.data.filter(x => x.antwoord =='3-6 keer per week').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_1_2_week = response.data.filter(x => x.antwoord =='1-2 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_1_2_week = response.data.filter(x => x.antwoord =='1-2 keer per week').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_less_1_week = response.data.filter(x => x.antwoord =='< 1 keer per week').map(d => d.dementienetwerknaam)
                this.antwoord_less_1_week = response.data.filter(x => x.antwoord =='< 1 keer per week').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_less_1_maand = response.data.filter(x => x.antwoord =='< 1 keer per maand').map(d => d.dementienetwerknaam)
                this.antwoord_less_1_maand = response.data.filter(x => x.antwoord =='< 1 keer per maand').map(d => (d.normalized_percentage))
                this.dementienetwerkregionaam_wisselend = response.data.filter(x => x.antwoord =='Wisselend').map(d => d.dementienetwerknaam)
                this.antwoord_wisselend = response.data.filter(x => x.antwoord =='Wisselend').map(d => (d.normalized_percentage))

                this.dementienetwerkregionaam_frequentie = response.data.map(d => d.dementienetwerknaam)
                this.groep_frequentie = response.data.map(x => x.antwoord)
                this.perc_frequentie =response.data.map(d => (d.normalized_percentage).toFixed(1))
                this.load.graphFive=true
              }),
          this.$http.get(`/api/dementienetwerk_mantelzorgervarenbelasting/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_nauwelijks = response.data.filter(x => x.antwoord =='Niet of nauwelijks').map(d => d.dementienetwerknaam)
              this.antwoord_nauwelijks = response.data.filter(x => x.antwoord =='Niet of nauwelijks').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_enigszins = response.data.filter(x => x.antwoord =='Enigszins').map(d => d.dementienetwerknaam)
              this.antwoord_enigszins = response.data.filter(x => x.antwoord =='Enigszins').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_tamelijkzwaar = response.data.filter(x => x.antwoord =='Tamelijk zwaar').map(d => d.dementienetwerknaam)
              this.antwoord_tamelijkzwaar = response.data.filter(x => x.antwoord =='Tamelijk zwaar').map(d => (d.normalized_percentage))
  
              this.dementienetwerkregionaam_zeerzwaar = response.data.filter(x => x.antwoord =='Zeer zwaar').map(d => d.dementienetwerknaam)
              this.antwoord_zeerzwaar = response.data.filter(x => x.antwoord =='Zeer zwaar').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_overbelast = response.data.filter(x => x.antwoord =='Overbelast').map(d => d.dementienetwerknaam)
              this.antwoord_overbelast = response.data.filter(x => x.antwoord =='Overbelast').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_som = response.data.filter(x => x.antwoord =='SOM: Tamelijk, (zeer) zwaar of overbelast').map(d => d.dementienetwerknaam)
              this.antwoord_som = response.data.filter(x => x.antwoord =='SOM: Tamelijk, (zeer) zwaar of overbelast').map(d => (d.normalized_percentage))

              this.dementienetwerkregionaam_belasting = response.data.map(d => d.dementienetwerknaam)
              this.groep_belasting = response.data.map(x => x.antwoord)
              this.perc_belasting = response.data.map(d => (d.percentage).toFixed(1))
              this.load.graphSix=true
            }),
          this.$http.get(`/api/dementienetwerk_aantaluren/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_aantaluren = response.data.filter(x => x.antwoord =='Gemiddelde aantal uren').map(d => d.dementienetwerknaam)
              this.antwoord_aantaluren = response.data.filter(x => x.antwoord =='Gemiddelde aantal uren').map(d => (d.percentage))
              
              this.load.graphSeven=true
            }),
          this.$http.get(`/api/dementienetwerk_levenrapportcijfer/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_levenrapportcijfer = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => d.dementienetwerknaam)
              this.antwoord_levenrapportcijfer = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => parseFloat(d.percentage.toFixed(1)))
              this.load.graphEight=true
            }),
          this.$http.get(`/api/dementienetwerk_socialeleven/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a,b) => b.percentage-a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
              this.dementienetwerkregionaam_socialeleven = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => d.dementienetwerknaam)
              this.antwoord_socialeleven = response.data.filter(x => x.antwoord =='Gemiddelde cijfer').map(d => (d.percentage))
              this.load.graphNine=true
            })
        }
    },
    loadNationalData () {
      this.$http.get(`/api/mantelzorg/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.t_samen = response.data.filter(x => x.samenwonend_overlijden===1).map(d => (d.t))
          this.antidepressiva_samen = response.data.filter(x => x.samenwonend_overlijden===1).map(d => (d.percentage_antidepressiva))
          this.psycho_samen = response.data.filter(x => x.samenwonend_overlijden===1).map(d => (d.percentage_psychofarmaca))
          this.vh_samen = response.data.filter(x => x.samenwonend_overlijden===1).map(d => (d.percentage_aantal_verpleeghuis))
          this.wmo_zonder_samen = response.data.filter(x => x.samenwonend_overlijden===1).map(d => (d.percentage_wmo_zonder_hulpmiddelen))
          this.t_alleen = response.data.filter(x => x.samenwonend_overlijden===0).map(d => (d.t))
          this.antidepressiva_alleen = response.data.filter(x => x.samenwonend_overlijden===0).map(d => (d.percentage_antidepressiva))
          this.psycho_alleen = response.data.filter(x => x.samenwonend_overlijden===0).map(d => (d.percentage_psychofarmaca))
          this.vh_alleen = response.data.filter(x => x.samenwonend_overlijden===0).map(d => (d.percentage_aantal_verpleeghuis))
          this.wmo_zonder_alleen = response.data.filter(x => x.samenwonend_overlijden===0).map(d => (d.percentage_wmo_zonder_hulpmiddelen))

          const tMapping = {
              '-2': '2 jaar voor overlijden',
              '-1': '1 jaar voor overlijden',
              '0': 'jaar van overlijden persoon met dementie',
              '1': '1 jaar na overlijden',
              '2': '2 jaar na overlijden'
            };

          const groepMapping = {
            '0': 'Niet samenwonend',
            '1': 'Samenwonend',
          };

            // Map the values using the defined mapping
          this.t_antidepressiva = response.data.map(d => tMapping[d.t]);
          this.groep_antidepressiva = response.data.map(d => groepMapping[d.samenwonend_overlijden]);
          this.antidepressiva_perc = response.data.map(d => (d.percentage_antidepressiva).toFixed(1))
          this.psycho_perc = response.data.map(d => (d.percentage_psychofarmaca).toFixed(1))
          this.wmo_zonder_perc = response.data.map(d => (d.percentage_wmo_zonder_hulpmiddelen).toFixed(1))
          this.vh_perc = response.data.map(d => (d.percentage_aantal_verpleeghuis).toFixed(1))
          this.load.graphTwo=true
        }
      )
    }
  },
  mounted () {
  this.loadAllData();
  this.loadNationalData();

  const firstCard = this.$refs.firstCard;
  if (firstCard) {
    const infoIcon = firstCard.$refs.infoIcon;
    if (infoIcon) {
      // focus on the info icon of the first card
      infoIcon.focus();
    }
  }
}

}

</script>

<style scoped>

.data-information{ 
  padding: 50px 0 50px 0;
  margin: 30px 0 30px 0;
  background: #a0b0c4;
}

.data-small-section{
  padding: 50px 0 50px 0;
  margin: 80px 0 80px 0;
 }

 .card-text{
  background: #fff;
  padding: 50px;
  border: 5px solid #fff;
  border-radius: 15px;
 }

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}


.first-section-colour-block {
  background: var(--color-9);
  color: black;
  padding: 7% 10% 5% 10%;
  width: 100%;
  position: relative;
}

.first-section-cc {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to right bottom, #a0b0c4 49%, #fff 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
}

.first-section-c {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to left bottom, #fff 49%, #a0b0c4 50%);
  /*margin-top: -100px;*/
}

.first-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

.intro-section{
  margin: 20px 0 20px 0;
 }

 /* SECOND SECTION */

 .section-space{
  margin-top: 5rem;
 }

 .second-section-colour-block {
  background: var(--color-9);
  width: 100%;
  padding: 5% 10% 5% 10%;
  position: relative;
}

.second-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

 .custom-button {
  text-align:left; 
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: #3e3a39;
  border: none;
  outline: none; 
  font-weight: bold;
  border-radius: 20px;
}
.custom-button:hover,
.custom-button:focus {
  text-align:left; 
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: var(--color-1);
  border: none;
  outline: none;
  transform:scale(1.1,1.1);
  -webkit-transform:scale(1.1,1.1);
  -moz-transform:scale(1.1,1.1);
}

.custom-link {
  color: var(--color-12);
  margin-right: 0px !important;
  outline: none;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.custom-link:link {
  color: var(--color-12);
}

.custom-link:visited {
  color: var(--color-12);
}


.custom-link:hover {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-12);
  border-radius: .5em;
}

.custom-link:focus {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-12);
  border-radius: .5em;
}
</style>